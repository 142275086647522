.App {
  text-align: center;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

a {
  text-decoration: none;
  font-weight: 700;
  color: #0ac169;
}

.home__link {
  position: relative;
}

.home__link:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 0;
  bottom: -3px;
  right: 0;
  background-color: #0ac169;

  transition: all 200ms ease;
}

.home__link:hover:after {
  width: 100%;
  left: 0;
}
